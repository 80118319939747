import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { OBJECT_STATE_SLICE } from "./state";
import { objectReducer } from "./reducer";

@NgModule({
	imports: [
		StoreModule.forFeature(OBJECT_STATE_SLICE, objectReducer)
	]
})
export class ObjectStateModule {}