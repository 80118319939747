import { Injectable } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BaseContentService, Content, FieldType, IContent, IContentConfig } from '@soleran/contracts';
import {
	FormMode,
	LayoutFormService,
	LayoutMode,
} from '@soleran/ngx-layout-utility';
import { ModuleService } from '@soleran/ngx-module';
import { combineLatest, map, mergeMap, Observable, of, take, tap } from 'rxjs';
import { GlobalDrawerService } from '../../global-drawer/global-drawer.service';
import { FieldModalComponent } from '../modals/field/field-modal.component';
import { ControlService } from '@soleran/ngx-control';
import { ObjectSelectors } from '../../_state/object/selectors';
import { ObjectActions } from '../../_state/object/actions';

@Injectable({
	providedIn: 'root'
})
export class FieldContentService extends BaseContentService {
	moduleCache: Record<string, { module: any, timestamp: number }> = {};
	constructor(
		private _moduleService: ModuleService,
		private _store: Store,
		private _controlService: ControlService,
		private _formService: LayoutFormService,
		private _drawerService: GlobalDrawerService
	) {
		super();
	}

	override canHandle(type: Content): boolean {
		return type === Content.Field;
	}

	override getInputs(contentConfig: any): Observable<any> {
		const moduleId = contentConfig.identifier.moduleId;
		const fieldId = contentConfig.identifier.fieldId;
		const module$ = contentConfig.module ? of(contentConfig.module) : this._moduleService.get(moduleId, { useCache: true });
		const record$ = of(contentConfig.record);
		const form = this._formService.get();
		const layoutMode$ = of(contentConfig.layoutMode !== undefined ? contentConfig.layoutMode : LayoutMode.View);
		const formMode$ = of(contentConfig.formMode !== undefined ? (contentConfig.formMode === FormMode.Edit ? 'edit' : 'readonly') : 'edit');
		const inputs = combineLatest([record$, module$, formMode$, layoutMode$]).pipe(
			take(1),
			map(([record, module, mode]) => {
				const field = module.fields?.find((field) => field.id === fieldId);

				const value = (record && field) ? record[field.id] : undefined;

				let control = form?.get(field?.id) as AbstractControl;

				if (!control) {
					if (field) {
						control = this._controlService.toControl(field, record);
						form?.addControl(field.id, control);
					}
				} else {
					// If the control exists, update its value
					control.setValue(value ?? '');
					control.enable(); // Optionally re-enable the control if necessary
				}
				const inputs = {
					field,
					control,
					form,
					mode
				};

				return inputs;
			})
		);

		return inputs;
	}
	private _getModule(moduleId) {
		const currentTime = Date.now();

		if (this.moduleCache[moduleId] && (currentTime - this.moduleCache[moduleId].timestamp) < 60000) {
			return of(this.moduleCache[moduleId].module);
		}
		const module$ = this._moduleService.get(moduleId).pipe(
			tap(module => {
				this.moduleCache[moduleId] = { module, timestamp: currentTime };
			})
		);
		return module$;
	}

	override get = (config?: IContentConfig) => {
		const module = config?.module;
		if (!module) return of([]);
		return of(module).pipe(
			map((module) => {
				const fields = module.fields;
				const contents = fields?.map((field) => {
					const identifier = {
						moduleId: module.id,
						fieldId: field.id
					};
					const content: IContent = {
						id: identifier,
						type: Content.Field,
						display: field.name
					};
					return content;
				}).sort((a,b) => a.display?.localeCompare(b.display));;
				return contents ?? [];
			})
		);
	};

	override getOne = (id: string, config?: IContentConfig) => {
		const module = config?.module;
		if (!module) return of(null);
		return of(module).pipe(
			map((module) => {
				const field = module.fields?.find((field) => field.id === id);
				if (!field) throw Error(`Content - Field with id ${id} not found.`);
				return { module, field };
			}),
			map(({ module, field }) => {
				const identifier = {
					moduleId: module.id,
					fieldId: field.id
				};
				const content: IContent = {
					id: identifier,
					type: Content.Field,
					display: field.name
				};
				return content;
			})
		);
	};
	override create = (config: any) => {
		const module$ = this._store.select(ObjectSelectors.selectedObject);
		return module$.pipe(
			take(1),
			mergeMap((module) => {
				const inputs = {
					module
				};
				return this._drawerService
					.open(FieldModalComponent, inputs)
					.pipe(map((field) => ({ module, field })));
			}),
			mergeMap(({ module, field }) => {
				if (field) {
					const updateModule = structuredClone(module);
					updateModule.fields.push(field);
					const moduleUpdated$ = this._moduleService.update(updateModule.id, updateModule);
					return moduleUpdated$.pipe(
						tap(selectedObject => this._store.dispatch(ObjectActions.setSelectedObject({ selectedObject }))),
						map(_ => {
							const content: IContent = {
								id: {
									moduleId: module.id,
									fieldId: field.id
								},
								display: field.name,
								type: Content.Field
							};
							return content;
						}))
				} else return of(undefined);
			})
		);
	};

	override update = (config: any) => {
		const moduleId = config.moduleId;
		const fieldId = config.fieldId;
		const module$ = this._store.select(ObjectSelectors.selectedObject);

		return module$.pipe(
			take(1),
			mergeMap((module) => {
				const field = module.fields.find((f) => f.id === fieldId);
				const inputs = {
					module,
					field
				};
				return this._drawerService
					.open(FieldModalComponent, inputs)
					.pipe(map((field) => ({ module, field })));
			}),
			mergeMap(({ module, field }) => {
				if (field) {
					const updateModule = structuredClone(module);
					const updateIndex = updateModule.fields.findIndex((f) => f.id === field.id);
					if (updateIndex === -1)
						throw Error('Failed to find field index for field with id ' + field.id);
					updateModule.fields[updateIndex] = field;
					return this._moduleService.update(updateModule.id, updateModule).pipe(
						tap(selectedObject => this._store.dispatch(ObjectActions.setSelectedObject({ selectedObject }))),
						map((_) => config),
					);
				} else return of(undefined);
			}),
		);
	};
}
