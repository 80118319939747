import { inject, Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	GuardResult,
	MaybeAsync,
	Router
} from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppActions, AppSelectors } from '@soleran/ngx-app';
import { of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { getRouteParentPath } from '../functions/route';

@Injectable({ providedIn: 'root' })
export class AppGuard implements CanActivate {
	private _router = inject(Router);

	private _store = inject(Store);
	private _actions = inject(Actions);

	canActivate(route: ActivatedRouteSnapshot): MaybeAsync<GuardResult> {
		const name = route.params['appName'];
		return this._store.select(AppSelectors.app).pipe(
			switchMap((app) => {
				if (!app || app.name != name) {
					return this._actions.pipe(
						ofType(AppActions.loadAppSuccess, AppActions.loadAppError),
						first(),
						switchMap((action) => {
							if (action.type == AppActions.loadAppSuccess.type) {
								return of(true);
							} else {
								return this._router.navigate([
									!!route.parent ? getRouteParentPath(route.parent) : '/'
								]);
							}
						})
					);
				}
				return of(true);
			})
		);
	}
}
