import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from '@soleran/ngx-auth';
import { PrimaryNavigationComponent } from './shared/navigation/primary/primary.component';
import { SecondaryNavigationComponent } from './shared/navigation/secondary/secondary.component';
import { WorkspaceGuard } from './shared/guards/workspace.guard';
import { AppGuard } from './shared/guards/app.guard';

//TODO; Update auth state change refresh, base off route data field?
//If '!data.isReactive' redirect to home?
const routes: Routes = [
	{
		path: 'login',
		loadChildren: () => import('./pages/login/login-page.module').then((m) => m.LoginPageModule)
	},
	{
		path: 'help',
		loadChildren: () => import('./pages/help/help.module').then((m) => m.HelpModule)
	},
	{
		path: '',
		canActivate: [authGuard],
		children: [
			{
				path: '',
				component: PrimaryNavigationComponent,
				children: [
					{
						path: '',
						pathMatch: 'full',
						redirectTo: 'home'
					},
					{
						path: 'user',
						loadChildren: () =>
							import('./pages/user/user-page.module').then((m) => m.UserPageModule)
					},
					{
						path: 'home',
						pathMatch: 'full',
						loadChildren: () =>
							import('./pages/home/home-page.module').then((m) => m.HomePageModule)
					},
					{
						path: 'account',
						loadChildren: () =>
							import('./pages/account/account-page.module').then((m) => m.AccountPageModule)
					},
					{
						path: ':workspaceName',
						pathMatch: 'full',
						canActivate: [WorkspaceGuard],
						loadChildren: () =>
							import('./pages/workspace/workspace-page.module').then((m) => m.WorkspacePageModule)
					},
					{
						path: ':workspaceName/marketplace',
						pathMatch: 'full',
						loadChildren: () => import('./pages/marketplace/marketplace-page.module').then((m) => m.MarketplacePageModule)
					},
				]
			},
			{
				path: ':workspaceName',
				canActivate: [WorkspaceGuard],
				children: [
					{
						path: ':appName',
						canActivate: [AppGuard],
						children: [
							{
								path: 'admin',
								loadChildren: () => import('./pages/admin/admin.module').then((m) => m.AdminModule)
							},
							{
								path: '',
								component: SecondaryNavigationComponent,
								children: [
									{
										path: '',
										pathMatch: 'full',
										loadChildren: () =>
											import('./pages/workspace/app-list-page/app-page/app-page.module').then(
												(m) => m.AppPageModule
											)
									},
									{
										path: 'admin',
										loadChildren: () =>
											import('./pages/admin/admin.module').then((m) => m.AdminModule)
									},
									{
										path: 'chart',
										loadChildren: () =>
											import('./pages/chart/chart-page.module').then((m) => m.ChartPageModule)
									},
									{
										path: 'dashboard',
										loadChildren: () =>
											import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule)
									},
									{
										path: 'object',
										loadChildren: () =>
											import('./pages/module/modules-page.module').then((m) => m.ModulesPageModule)
									},
									{
										path: 'report',
										loadChildren: () =>
											import('./pages/report/report.module').then((m) => m.ReportPageModule)
									}
								]
							}
						]
					},
				]
			}
		]
	},
	{ path: '**', redirectTo: '' } //404
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			// enableTracing: true,
			bindToComponentInputs: true
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
