import { createReducer, on } from "@ngrx/store";
import { LayoutActions } from "./actions";
import { LAYOUT_INITIAL_STATE } from "./state";

export const layoutReducer = createReducer(
	LAYOUT_INITIAL_STATE,
	on(LayoutActions.setLayoutMode, (state, { layoutMode }) => {
		return { ...state, layoutMode };
	}),
	on(LayoutActions.setFormMode, (state, { formMode }) => {
		return { ...state, formMode };
	}),
	on(LayoutActions.setRecord, (state, { record }) => {
		return { ...state, record };
	})
);