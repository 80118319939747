import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION, RouterNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { AccountSelectors } from '@soleran/ngx-account';
import { WorkspaceActions } from '@soleran/ngx-workspace';
import { distinctUntilChanged, first, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { RouterSelectors } from '../router/selectors';
import { WorkspaceRouterActions } from './actions';
import { AuthSelectors } from '@soleran/ngx-auth';

@Injectable()
export class WorkspaceRouterEffects {
	private _store = inject(Store);
	private _actions$ = inject(Actions);

	private _user = this._store.selectSignal(AuthSelectors.user);

	routeNameChanged$ = createEffect(() =>
		this._actions$.pipe(
			ofType<RouterNavigatedAction>(ROUTER_NAVIGATION),
			withLatestFrom(this._store.select(RouterSelectors.ancestorParam('workspaceName'))),
			map(([action, name]) => name),
			distinctUntilChanged(),
			map((name = null) => {
				if (!this._user() && !!name) {
					return WorkspaceRouterActions.loadWorkspaceDeferred({ name });
				} else if (!!name) {
					return WorkspaceActions.loadWorkspace({ name });
				}
				return WorkspaceActions.resetWorkspace();
			})
		)
	);

	loadWorkspaceDeferred$ = createEffect(() =>
		this._actions$.pipe(
			ofType(WorkspaceRouterActions.loadWorkspaceDeferred),
			switchMap((props) =>
				this._store.select(AccountSelectors.state).pipe(
					first((state) => !!state.account && !state.loading),
					map(() => props)
				)
			),
			map((props) => WorkspaceActions.loadWorkspace(props))
		)
	);
}
