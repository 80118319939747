import { isDevMode, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import {
	DEFAULT_ROUTER_FEATURENAME,
	routerReducer,
	StoreRouterConnectingModule
} from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppStateModule } from '@soleran/ngx-app';
import { WorkspaceStateModule } from '@soleran/ngx-workspace';
import { WorkspaceRouterEffects } from './workspace';
import { AppRouterEffects } from './app';
import { ObjectStateModule } from './object/module';
import { LayoutStateModule } from './layout/module';

@NgModule({
	imports: [
		WorkspaceStateModule,
		AppStateModule,
		ObjectStateModule,
		LayoutStateModule,
	
		StoreModule.forRoot({
			[DEFAULT_ROUTER_FEATURENAME]: routerReducer
		}),
		EffectsModule.forRoot(
			[WorkspaceRouterEffects, AppRouterEffects]
		),
		StoreRouterConnectingModule.forRoot(),
		StoreDevtoolsModule.instrument({
			name: 'Soleran',
			maxAge: 25,
			connectInZone: true,
			logOnly: !isDevMode()
		})
	]
})
export class StateModule {}
