import { NgModule } from '@angular/core';
import { AccountModule } from '@soleran/ngx-account';
import { AppModule as ApplicationModule } from '@soleran/ngx-app';
import { AuthModule } from '@soleran/ngx-auth';
import { UserDialogModule } from '@soleran/ngx-auth-utility';
import { ChartModule } from '@soleran/ngx-chart';
import { IconPickerModule } from '@soleran/ngx-common';
import { ContentModule } from '@soleran/ngx-content';
import { DirectoryModule } from '@soleran/ngx-directory';
import { EventModule } from '@soleran/ngx-event';
import { FileModule } from '@soleran/ngx-file';
import { ExportModule, ImportModule } from '@soleran/ngx-import-export';
import { LayoutModule } from '@soleran/ngx-layout';
import { ModuleModule } from '@soleran/ngx-module';
import { PageModule } from '@soleran/ngx-page';
import { ProfileModule } from '@soleran/ngx-profile';
import { RecordModule } from '@soleran/ngx-record';
import { ReportConfigModule, ReportLogModule, ReportModule } from '@soleran/ngx-report';
import { ReportBuilderModule } from '@soleran/ngx-report-builder';
import { RoleModule } from '@soleran/ngx-role';
import { TemplateModule } from '@soleran/ngx-template';
import { WorkspaceModule } from '@soleran/ngx-workspace';
import { apiKey, authDomain, tld } from 'src/app/shared/functions/util';
import { CONTENT_SERVICES } from './shared/content/content-registry';
import { ChatModule } from '@soleran/ngx-chat';
import { ControlModule } from '@soleran/ngx-control';
import { WorkflowDataModule } from '@soleran/ngx-workflow';

const SERVERLESS_API_BASE_URL = `https://serverless.soleran.${tld}`;

@NgModule({
	imports: [
		AccountModule.forRoot({ apiBaseUrl: SERVERLESS_API_BASE_URL }),
		ApplicationModule.forRoot({ apiBaseUrl: SERVERLESS_API_BASE_URL }),
		AuthModule.forRoot({
			identityConfig: { apiKey: apiKey, authDomain: authDomain },
			apiBaseUrl: SERVERLESS_API_BASE_URL,
			externalDomains: ['localhost:3001', 'localhost:3000'],
			loginRoute: '/login'
		}),
		ChartModule.forRoot({ apiBaseUrl: SERVERLESS_API_BASE_URL }),
		ChatModule.forRoot({
			//TODO; Port API to Serverless
			apiBaseUrl: `https://chat.soleran.${tld}` //`http://localhost:3000`
		}),
		ChartModule.forRoot({ apiBaseUrl: SERVERLESS_API_BASE_URL }),
		ContentModule.registerContentService(CONTENT_SERVICES),
		ControlModule,
		DirectoryModule.forRoot({ apiBaseUrl: SERVERLESS_API_BASE_URL }),
		EventModule.forRoot({ apiBaseUrl: `https://event.soleran.${tld}` }), //TODO; Port API to Serverless
		ExportModule,
		FileModule.forRoot({ apiBaseUrl: `https://file.soleran.${tld}` }), //TODO; Port API to Serverless
		IconPickerModule.forRoot({ apiBaseUrl: `https://static.soleran.${tld}` }), //S3 Bucket URL
		ImportModule.forRoot({ apiBaseUrl: `https://import.soleran.${tld}` }), //TODO; Port API to Serverless
		LayoutModule.forRoot({ apiBaseUrl: SERVERLESS_API_BASE_URL }),
		ModuleModule.forRoot({ apiBaseUrl: SERVERLESS_API_BASE_URL }),
		PageModule.forRoot({ apiBaseUrl: SERVERLESS_API_BASE_URL }),
		ProfileModule.forRoot({ apiBaseUrl: SERVERLESS_API_BASE_URL }),
		RecordModule.forRoot({ apiBaseUrl: SERVERLESS_API_BASE_URL }),
		ReportBuilderModule.forRoot({ apiBaseUrl: SERVERLESS_API_BASE_URL }),
		ReportConfigModule.forRoot({ apiBaseUrl: SERVERLESS_API_BASE_URL }),
		ReportLogModule.forRoot({ apiBaseUrl: SERVERLESS_API_BASE_URL }),
		ReportModule.forRoot({ apiBaseUrl: SERVERLESS_API_BASE_URL }),
		RoleModule.forRoot({ apiBaseUrl: SERVERLESS_API_BASE_URL }),
		TemplateModule.forRoot({ apiBaseUrl: SERVERLESS_API_BASE_URL }),
		UserDialogModule,
		WorkflowDataModule.forRoot({ apiBaseUrl: SERVERLESS_API_BASE_URL }),
		WorkspaceModule.forRoot({ apiBaseUrl: SERVERLESS_API_BASE_URL })
	]
})
export class AppServiceModule {}
