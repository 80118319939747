import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ContentModule } from './content/content.module';
import { LogoModule } from './logo/logo.module';
import { NavigationModule } from './navigation/navigation.module';
import { ThemeModule } from './theme/theme.module';

@NgModule({
	imports: [
		BrowserModule,
		CommonModule,
		RouterModule,
		NavigationModule,
		ContentModule,
		LogoModule,
		ThemeModule
	]
})
export class SharedModule {}
