import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { LAYOUT_STATE_SLICE } from "./state";
import { layoutReducer } from "./reducer";

@NgModule({
	imports: [
		StoreModule.forFeature(LAYOUT_STATE_SLICE, layoutReducer)
	]
})
export class LayoutStateModule {}