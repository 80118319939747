import { createReducer, on } from "@ngrx/store";
import { ObjectActions } from "./actions";
import { OBJECT_INITIAL_STATE } from "./state";

export const objectReducer = createReducer(
	OBJECT_INITIAL_STATE,
	on(ObjectActions.setSelectedObject, (state, { selectedObject }) => {
		return { ...state, module: selectedObject };
	}),
	on(ObjectActions.setSelectedPage, (state, { selectedPage }) => {
		return { ...state, page: selectedPage };
	})
);