import { ChartContentService } from './services/chart-content.service';
import { FieldContentService } from './services/field-content.service';
import { ReportContentService } from './services/report-content.service';
import { ChartModalComponents } from './modals/chart';
import { FieldModalComponent } from './modals/field/field-modal.component';
import { ModuleModalComponent } from './modals/module/module-modal.component';
import { PageModalComponent } from './modals/page/page-modal.component';
import { RecordModalComponent } from './modals/record/record-modal.component';

export const CONTENT_SERVICES = [ChartContentService, FieldContentService, ReportContentService];
export const CONTENT_MODAL_COMPONENTS = [
	...ChartModalComponents,
	FieldModalComponent,
	ModuleModalComponent,
	RecordModalComponent,
	PageModalComponent
];
