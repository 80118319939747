import { inject, Injectable } from '@angular/core';
import {
	BaseContentService,
	ChartEventType,
	ChartType,
	Content,
	IChartConfig,
	IContent,
	IRecordFilter
} from '@soleran/contracts';
import { ChartConfig, ChartConfigService } from '@soleran/ngx-chart';
import { ContentEvent } from '@soleran/ngx-content';
import { combineLatest, map, mergeMap, Observable, of, switchMap, take } from 'rxjs';
import { GlobalDrawerService } from '../../global-drawer/global-drawer.service';
import { ChartEditorModalComponent, ChartViewModalComponent } from '../modals/chart';
import { ReportConfigService } from '@soleran/ngx-report';
import { Store } from '@ngrx/store';
import { LayoutSelectors } from '../../_state/layout/selectors';
import { ObjectSelectors } from '../../_state/object/selectors';

@Injectable({
	providedIn: 'root'
})
export class ChartContentService extends BaseContentService {
	private _drawerService = inject(GlobalDrawerService);
	private _chartConfigService = inject(ChartConfigService);
	private _reportConfigService = inject(ReportConfigService);
	private _store = inject(Store)

	override canHandle(type: Content): boolean {
		return type === Content.Chart;
	}

	override getInputs(id: any): Observable<{ config: ChartConfig }> {
		const record$ = this._store.select(LayoutSelectors.record);
		const module$ = this._store.select(ObjectSelectors.selectedObject);
		const chartConfig$ = this._chartConfigService.get({ id: id.identifier });
		const filteredChartConfig$ = combineLatest([chartConfig$, module$, record$]).pipe(
			take(1),
			map(([chartConfig, module, record]) => {
				if (!module || !record) return chartConfig;
				const filteredChartConfig: IChartConfig = { ...chartConfig };
				const recordFilter: IRecordFilter = {
					moduleId: module.id,
					recordId: record.id
				};
				filteredChartConfig.data.recordFilter = recordFilter;
				return filteredChartConfig;
			})
		)
		return filteredChartConfig$.pipe(
			map((config) => {
				if (!config) {
					throw new Error(`Content - Chart '${id}' Not Found`);
				}
				return { config };
			})
		);
	}

	override get = (config: any) => {
		const chartConfigs$ = this._chartConfigService.get();
		return chartConfigs$.pipe(
			mergeMap(chartConfigs => {
				if (!config.moduleId) return of(chartConfigs);
				return this.getFilteredChartConfigs$(chartConfigs, config);
			}),
			map((chartConfigs) => {
				return chartConfigs.map((chartConfig) => {
					const content: IContent = {
						id: chartConfig.id,
						type: Content.Chart,
						display: chartConfig.name
					};
					return content;
				}).sort((a,b) => a.display?.localeCompare(b.display));
			})
		);
	};

	override getOne = (id: string) => {
		return this._chartConfigService.get({ id }).pipe(
			map((chartConfig) => {
				if (!chartConfig) {
					throw new Error(`Content - Chart '${id}' Not Found`);
				}
				const content: IContent = {
					id: chartConfig.id,
					type: Content.Chart,
					display: chartConfig.name
				};
				return content;
			})
		);
	};

	override create = () => {
		return this._drawerService
			.open<ChartConfig | null>(ChartEditorModalComponent, {
				config: {
					name: '',
					type: ChartType.BarVertical,
					reportId: '',
					display: {},
					data: {
						_hash: '',
						groups: [],
						values: []
					}
				}
			})
			.pipe(map((config) => config.id));
	};

	override update = (id: string) => {
		return this._chartConfigService.get({ id }).pipe(
			switchMap((config) => {
				if (!config) return of(null);
				return this._drawerService
					.open<ChartConfig>(ChartEditorModalComponent, { config })
					.pipe(map((config) => config.id));
			})
		);
	};

	//TODO; Delete

	override handleEvent = (config: ContentEvent) => {
		switch (config.contentEventType) {
			case ChartEventType.View:
				return this._handleViewEvent(config);
			case ChartEventType.ReportLink:
				return this._handleReportLinkEvent(config);
			default:
				return of();
		}
	};

	private _handleViewEvent(config: ContentEvent): Observable<void> {
		return this._drawerService.open(ChartViewModalComponent, config.event);
	}

	private _handleReportLinkEvent(config: ContentEvent): Observable<void> {
		const reportId = config.event.reportId;
		const url = new URL(`/report/${reportId}`, window.location.origin);
		window.open(url, '_blank');
		return of();
	}

	private getFilteredChartConfigs$(chartConfigs: any[], config: any): Observable<any[]> {
		const reportConfigs$ = this._reportConfigService.get();
		const chartDetails$ = reportConfigs$.pipe(
			map(reportConfigs => {
				return chartConfigs.map(chartConfig => {
					const reportConfig = reportConfigs.find(r => r.id === chartConfig.reportId);
					if (!reportConfig) throw Error('Failed to find report for chart with report id ' + chartConfig.reportId);
					return { chartConfig, reportConfig };
				});
			})
		);

		const filteredChartConfigs$ = chartDetails$.pipe(
			map(chartDetails => chartDetails.filter(chartDetail => {
				const reportConfig = chartDetail.reportConfig;
				const primaryModuleId = [reportConfig.moduleId];
				const joinedModuleIds = reportConfig.joinConfigs?.map(joinConfig => joinConfig.rightModuleId) ?? [];
				const reportModuleIds = joinedModuleIds.concat(primaryModuleId);
				return reportModuleIds.some(moduleId => moduleId === config.moduleId);
			})),
			map(chartDetails => chartDetails.map(chartDetail => chartDetail.chartConfig))
		);

		return filteredChartConfigs$;
	}
}
