import { Component, DestroyRef, NgZone, OnInit, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { fontLoaded, IClientUserToken } from '@soleran/contracts';
import { AuthService } from '@soleran/ngx-auth';
import { UserPasswordDialogComponent } from '@soleran/ngx-auth-utility';
import { ChatInitializerService } from '@soleran/ngx-chat';
import { delay, first, forkJoin, map, Observable, of, share, startWith } from 'rxjs';

@Component({
	selector: 'app-root',
	styles: `
		.loading {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 2rem;
			width: 100%;
			height: 100%;
		}
	`,
	template: `
		<sol-guide-sidenav>
			@if (!(loading$ | async)) {
				<router-outlet />
			} @else {
				<div class="loading">
					<mat-spinner />
					<h3>Loading...</h3>
				</div>
			}
		</sol-guide-sidenav>
	`
})
export class AppComponent implements OnInit, OnDestroy {
	loading$: Observable<boolean> = of(true);
	private _temp: IClientUserToken | null;

	constructor(
		private router: Router,
		private destroyRef: DestroyRef,
		private zone: NgZone,
		private dialogService: MatDialog,
		private authService: AuthService
		// private chatInitializerService: ChatInitializerService
	) {
		this.loading$ = forkJoin([
			authService.onCurrentUser.pipe(delay(2000), first()),
			fontLoaded('Roboto'),
			fontLoaded('Material Icons')
		]).pipe(
			map(() => false),
			startWith(true),
			share()
		);

		// Track account changes and refresh as needed
		authService.onCurrentUser.pipe(takeUntilDestroyed(destroyRef)).subscribe((user) => {
			console.warn('Temporary Account Change Handler - REPLACE WITH REACTIVE DESIGN');
			if (!this._temp || !this._temp.claims) this._temp = user;
			else if (!!user?.claims && this._temp.claims.account_id !== user.claims.account_id) {
				this.router.navigate(['/']);
			}
		});

		// Handle password reset events
		authService.onPasswordReset.pipe(takeUntilDestroyed(destroyRef)).subscribe((user) => {
			if (dialogService.openDialogs.length == 0) {
				zone.run(() =>
					dialogService.open(UserPasswordDialogComponent, {
						data: { userId: user.claims.user_id, email: user.claims.email },
						autoFocus: false,
						closeOnNavigation: false,
						disableClose: true
					})
				);
			}
		});
	}

	ngOnInit() {
		// this.chatInitializerService.init();
	}

	ngOnDestroy() {
		// this.chatInitializerService.cleanup();
	}
}
