import {
	DEFAULT_ROUTER_FEATURENAME,
	getRouterSelectors,
	RouterReducerState
} from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export namespace RouterSelectors {
	export const state = createFeatureSelector<RouterReducerState>(DEFAULT_ROUTER_FEATURENAME);

	export const router = createSelector(state, (reducerState) => reducerState?.state);

	export const root = createSelector(router, (router) => router?.root);

	export const ancestorParam = (param: string) =>
		createSelector(root, (root) => {
			let route = root;
			while (!!route) {
				if (route.params && param in route.params) {
					return route.params[param];
				}
				route = route.firstChild;
			}
			return null;
		});

	export const {
		selectCurrentRoute: currentRoute,
		selectFragment: fragment,
		selectQueryParams: queryParams,
		selectQueryParam: queryParam,
		selectRouteParams: params,
		selectRouteParam: param,
		selectRouteData: data,
		selectRouteDataParam: dataParam,
		selectUrl: url,
		selectTitle: title
	} = getRouterSelectors(state);
}
