import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION, RouterNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { AppActions } from '@soleran/ngx-app';
import { WorkspaceSelectors } from '@soleran/ngx-workspace';
import { distinctUntilChanged, first, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { RouterSelectors } from '../router/selectors';
import { AppRouterActions } from './actions';

@Injectable()
export class AppRouterEffects {
	private _store = inject(Store);
	private _actions$ = inject(Actions);

	private _workspace = this._store.selectSignal(WorkspaceSelectors.workspace);

	routeNameChanged$ = createEffect(() =>
		this._actions$.pipe(
			ofType<RouterNavigatedAction>(ROUTER_NAVIGATION),
			withLatestFrom(this._store.select(RouterSelectors.ancestorParam('appName'))),
			map(([_, name]) => name),
			distinctUntilChanged(),
			map((name = null) => {
				if (!this._workspace() && !!name) {
					return AppRouterActions.loadAppDeferred({ name });
				} else if (!!name) {
					return AppActions.loadApp({ name });
				}
				return AppActions.resetApp();
			})
		)
	);

	loadAppDeferred$ = createEffect(() =>
		this._actions$.pipe(
			ofType(AppRouterActions.loadAppDeferred),
			switchMap((props) =>
				this._store.select(WorkspaceSelectors.state).pipe(
					first((state) => !!state.workspace && !state.loading),
					map(() => props)
				)
			),
			map((props) => AppActions.loadApp(props))
		)
	);
}
