import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

const modules = [
	MatButtonModule,
	MatIconModule,
	MatToolbarModule,
	MatDividerModule,
	MatSidenavModule,
	MatTabsModule,
	MatCardModule,
	MatTooltipModule,
	MatFormFieldModule,
	MatInputModule
];

@NgModule({
	imports: modules,
	exports: modules
})
export class ContentMaterialModule {}
